import React, { useState, useCallback, useContext, useEffect } from 'react';
import {app} from '../firebase';
import { AuthContext } from '../userContext/userContext';
import {Button, Form} from 'react-bootstrap';
import {url} from '../App';
import NavBar from '../Components/NavBar';
import './css/MissionPage.css';
import targetFolder from '../Images/targetFolder.svg'
import login from '../Images/login.svg'
import NavBar3 from '../Components/NavBar3';


const MissionPage =() =>{
    const {currentUser} = useContext(AuthContext);
    const [gameStats,setGameStats]=useState({});
    //console.log(currentUser);

    const [killCode,setKillCode]=useState();
    const [players,setPlayers]=useState();
    const [date,setDate]=useState();

    const stringDate = "Thu Feb 17 2022 17:13:42 GMT-0500 (Eastern Standard Time)";
    const fixedDate = "";

    useEffect(async()=>{
       var data= await fetch(url + "/info");
       data=await data.json()

        setPlayers(data.numberOfAlive);
        setDate(new Date(data.nextRoundStarts));
    },[])

    const DisplayDate = () => {
        var datestr;
        if (!date){
            datestr=new Date().toString();
        }
        else{
            datestr=date.toString();
        }
        
        const weekDay = datestr.split(' ')[0];
        const day = datestr.split(' ')[1] + ' ' + datestr.split(' ')[2];
        var time  = datestr.split(' ')[4];
        time = time.split(':'); // convert to array
        var hours = Number(time[0]);
        var minutes = Number(time[1]);
        
        // calculate
        var timeValue;
        
        if (hours > 0 && hours <= 12) {
          timeValue= "" + hours;
        } else if (hours > 12) {
          timeValue= "" + (hours - 12);
        } else if (hours == 0) {
          timeValue= "12";
        }
         
        timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;  // get minutes
        timeValue += (hours >= 12) ? " P.M." : " A.M.";  // get AM/PM        // time = time.split(':')[0] + time.split(':')[1];

        return (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '250px'}}>
                <h1 style={{fontSize: "30px", marginBottom: '0', alignSelf:" center"}}>DUE:</h1>
                <h1 style={{fontSize: "26px", marginBottom: '0', alignSelf:" center"}}>{weekDay} {day}</h1>
                <h1 style={{fontSize: "26px", marginBottom: '0', alignSelf:" center"}}>{timeValue}</h1>
            </div>

        )
    }

    
    const handleSubmit=async(event)=>{
        event.preventDefault();

        if (currentUser.toKill){
            console.log(killCode.toString());

            if (killCode.toString()===currentUser.toKill.randomNum.toString()){
                var website=url + "/kill?id="+currentUser.uid+"&code="+killCode;
                await fetch(website);
                window.location.reload();
            }
            else{
                alert("code is wrong")
            }

        }
        else{
            alert("No one assigned to you")
        }
        console.log(killCode);
    }

    return (
        <div className="mission-page-container font-face-gm">
            <NavBar3/>
            <div className="players-remaining-container ">
                <div style={{width: '82%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <h1 style={{fontSize: '40px', marginTop: '3.5%'}}>Players Remaining</h1>
                </div>
                <div className="players-count">
                    <h1 style={{fontSize: '40px', marginTop: '15%'}}>{players? players: "0"}</h1>
                </div>
            </div>
            <div className="target-container">
                <h1 style={{fontSize: '70px', marginTop: '2%'}}>Your Target</h1>
            </div>
            <div className="image-target-container"> 
                <img src={targetFolder} className="target-folder" />
                <div className="target-image-mission">
                    {currentUser.toKill.photoURL ?<img style={{width: '100%'}} src={currentUser.toKill.photoURL}></img>: ""}
                </div>
                <div className="target-description-mission">
                    <p style={{fontSize: '28px', color: '#30271e', marginBottom: '0'}}>Name</p>
                    {currentUser.toKill.displayName ? 
                    <p style={{fontSize: '28px', color: '#30271e', marginBottom: '0'}}>{currentUser.toKill.displayName}</p> 
                    : 
                    <p style={{fontSize: '28px', color: '#30271e', marginBottom: '0'}}>No Target yet</p>}     
                </div>
            </div>
            <div className="eliminate-container">
                <img src={login} className="eliminate" />
                
                <div  className="eliminate-text">
                    <DisplayDate />
                </div>              
            </div>

            
            <Form style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <div className="kill-code-form">
                        <input 
                            className="kill-code-input"
                            type="number" 
                            placeholder="Target Code" 
                            value={killCode} 
                            style={{backgroundColor: '#DDD6B3', borderRadius: '5px',
                                width: '90%', marginLeft: '5%',
                                fontSize: '20px', color: '#30271e', height: '6vh', marginBottom: '0', paddingLeft: '5%'
                            }} 
                            size="5"
                            onChange={ (event) => { setKillCode(event.target.value) } } 
                        />
                        <div className="eliminate-container" >
                            <img src={login} className="eliminate"/>
                            <div onClick={()=>handleSubmit()} className="eliminate-text" style={{width: '15%'}}>
                                <button  type='submit' className="eliminate-button">
                                    ELIMINATE
                                </button>
                            </div>              
                        </div>                        
                    </div>
                </Form.Group>
            </Form> 
        </div>
    );



};

export default MissionPage;