import React, { useState, useCallback, useContext, useEffect } from 'react';
import {app} from '../firebase'
import { AuthContext } from '../userContext/userContext';
import {Button, Form} from 'react-bootstrap'
import { url } from '../App';
import { signInWithGoogle } from '../firebase';



const AdminPage =() =>{
    const {currentUser} = useContext(AuthContext);

    const [pass,setPass]=useState("");
    const [option,setOption]=useState(5);

    const [gameStats, setGameStats]= useState({loading: true});

    const [reload, setReload]= useState(false);



    const DisplayDate = () => {
        var date=gameStats.nextRoundStarts;
        var datestr= (new Date()).toString();
        // if (!date){
        //     datestr=new Date().toString();
        // }
        // else{
        //     datestr=date.toString();
        // }
        
        const weekDay = datestr.split(' ')[0];
        const day = datestr.split(' ')[1] + ' ' + datestr.split(' ')[2];
        var time  = datestr.split(' ')[4];
        time = time.split(':'); // convert to array
        var hours = Number(time[0]);
        var minutes = Number(time[1]);
        
        // calculate
        var timeValue;
        
        if (hours > 0 && hours <= 12) {
          timeValue= "" + hours;
        } else if (hours > 12) {
          timeValue= "" + (hours - 12);
        } else if (hours == 0) {
          timeValue= "12";
        }
         
        timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;  // get minutes
        timeValue += (hours >= 12) ? " P.M." : " A.M.";  // get AM/PM        // time = time.split(':')[0] + time.split(':')[1];

        return (
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <h1 style={{fontSize: "16px"}}>Next Round: &nbsp;</h1>
                <h1 style={{fontSize: "16px"}}>{weekDay} {day+' '} </h1>
                <h1 style={{fontSize: "16px"}}> &nbsp; at {timeValue}</h1>
            </div>

        )
    }




    useEffect(async ()=>{
        await fetch(url + "/info")
            .then(response=>response.json())
            .then(data=>setGameStats(data));
        setReload(false);
    }
    ,[reload])

    const handleSubmit=async(event)=>{
        event.preventDefault();
        var val=parseInt(event.target.selec.value);
        
        if (pass==="sirquack" && (currentUser.email==="305jhanson@gmail.com" || currentUser.email==="technology.floridadm@gmail.com" )){

            if (val===2){
                
                fetch(url + "/startGame")
                .then(response=>response.json())
                .then(data=>console.log(data));
            }
            if (val===1){
                fetch(url + "/startRoundMatt")
                .then(response=>response.json())
                .then(data=>console.log(data));
            }
            if (val===3){
                fetch(url + "/sendReminderEmail")
                .then(response=>response.json())
                .then(data=>console.log(data));
            }
            
            setReload(true);
        }
        else{
            alert("wrong password or not authorized");
        }
    }



    if (currentUser && (currentUser.email==="305jhanson@gmail.com" || currentUser.email==="technology.floridadm@gmail.com" )){
    return (
        <div>
            <h1>Admin Page</h1>
            
           {DisplayDate()}
           <div style={{display: 'flex', flexDirection: 'row'}}>
                <h1 style={{fontSize: "16px"}}>Players Alive: &nbsp;</h1>
                <h1 style={{fontSize: "16px"}}>{gameStats.numberOfAlive} </h1>
            </div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <h1 style={{fontSize: "16px"}}>Players Safe: &nbsp;</h1>
                <h1 style={{fontSize: "16px"}}>{gameStats.numberOfSafe} </h1>
            </div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <h1 style={{fontSize: "16px"}}>Round Number: &nbsp;</h1>
                <h1 style={{fontSize: "16px"}}>{gameStats.roundNumber} </h1>
            </div>

            <h2>Game Controls</h2>
            <h4>In order to start the game you must set the startGame field in firestore collection gameStats  to true</h4>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="text" placeholder="Enter code" value={pass} onChange={ (event) => { setPass(event.target.value) } } />
                </Form.Group>
                <Form.Select name="selec" aria-label="Default select example">
                    <option>Open this select menu</option>
                    <option value="1">Start Next Round</option>
                    <option value="2">Start Game</option>
                    <option value="3">Send Reminder Email</option>
                </Form.Select>
                <Button type="submit" className="mb-2">
                    Submit
                </Button>

            </Form>
        </div>
    );}
    else{
        return(
            <div>
        <h1>Not matt hanson or change email on frontend to view and update page</h1>
           
            <a style={{color:"blue"}} onClick={signInWithGoogle} >
                            LOGIN
            </a>
                  
        
        </div>
        );
    }



};

export default AdminPage;