import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import React, { useContext } from 'react';
import { AuthContext } from '../userContext/userContext';
import './css/LandingPage2.css'
import homeBackgroundImage from '../Images/homeBackgroundImage.png'
import {app} from '../firebase'
import camera from '../Images/camera.png'
import folder from '../Images/folder.svg'
import login from '../Images/login.svg'
import {signInWithGoogle} from '../firebase';

const LandingPage2 =() =>{


    
    const logOut=()=>{
        app.auth().signOut();
    }

    return (
        <div className="landing-container">
            <div className="landing-top">
                <div className="font-face-gm title-header">
                    <h1 className="title">MISSION</h1>
                </div>
                <div className="font-face-gm title-header">
                    <h1 className="title title-bottom">DM</h1>
                </div>
            </div>
            <img src={camera} className="camera"/>
            <div className="about-container">
                <img src={folder} className="folder"/>
                <div className="about-text">
                    <div className="about-header font-face-gm">
                        <h1 style={{fontSize: "105px"}}>About</h1>
                    </div>
                    <p style={{fontSize: '18px', fontFamily: 'monospace'}}>
                        Mission DM is a spy-style game where the objective is to compromise your
                        target with silly string. Outlast your opponents and survive the game's
                        twists and turns, and you'll become the best Agent in DM.
                    </p>
                </div>
                <div className="login-container" style={{marginLeft: '5%'}}>
                    <img  src={login} className="login" />
                    <div className="login-text  font-face-gm">
                        <a onClick={signInWithGoogle} style={{color: 'black'}}>
                            <h1 style={{fontSize: "96px", marginBottom: '0', alignSelf:" center"}}>LOGIN</h1></a>
                    </div>              
                </div>
            </div>
        </div>
    );

};

export default LandingPage2;