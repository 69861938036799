import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import 'firebase/compat/storage';


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC8xLoOOFktdcm1owy0n-ujBi2UouBRIsw",
    authDomain: "missiondm-7f790.firebaseapp.com",
    projectId: "missiondm-7f790",
    storageBucket: "missiondm-7f790.appspot.com",
    messagingSenderId: "276751913213",
    appId: "1:276751913213:web:f05d2144e4e51ecd072e94",
    measurementId: "G-7SCCHWLD1S"
  };

// Initialize Firebase
export const app=firebase.initializeApp(firebaseConfig);

 //const auth = firebase.auth();
 const firestore = firebase.firestore();
 //const storage = firebase.storage();

//sign in with google
const provider = new firebase.auth.FacebookAuthProvider();
export const signInWithFacebook = () => {
  try{
    firebase.auth().signInWithPopup(provider);
  }
  catch (error){
    console.log(error);
    return false;
  }
  return true;
  };

  const provider2 = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = async() => {
  try{
    await firebase.auth().signInWithPopup(provider2);
    window.location.href='account';
  }
  catch (error){
    console.log(error);
  }
  return true;
  };



//create user infomation
export const generateUserDocument = async (user, additionalData) => {
    if (!user) return null; 
    const userRef = await firestore.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();
    if (!snapshot.exists) {
      const { email, displayName, photoURL } = user;
      try {
        await userRef.set({
          displayName,
          email,
          photoURL,
          randomNum: Math.floor(Math.random()*90000) + 10000,
          approved: false,
          toKill:"",
          killed:[],
          alive: false,
          safeThisRound: false,
          ...additionalData
        });
      } catch (error) {
        console.error("Error creating user document", error);
        return null;
      }
    }
    return getUserDocument(user.uid);
  };

  //get user data
  export const getUserDocument = async uid => {
    if (!uid) return null;
    try {
      var userDocument = await firestore.doc(`users/${uid}`).get();
      return {
        uid,
        ...userDocument.data()
      };
    } catch (error) {
      console.error("Error fetching user", error);
      return null;
    }
  };

  export const updateFirebaseObject = async (folderName,id,additionalData) => {
    if (!id || !folderName || !additionalData) return null;
      const ref = firestore.doc(`${folderName}/${id}`);
      const snapshot = await ref.get();
      console.log(snapshot);
      if (snapshot.exists) {
        return await ref.update(additionalData);
      }
      else{
        return null;
      }
    

  }




  export const handleUserUpload = async(user, folder,file, name) =>{
    if (!user) return ; 
    var storage = firebase.storage();
    var storageRef = storage.ref();
    var uploadTask = storageRef.child(folder + "/"+ user.uid +"/"+ name).put(file);
    var img="temp";

    await uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) =>{
        var progress = Math.round((snapshot.bytesTransferred/snapshot.totalBytes))*100;
        console.log(progress);
      },(error) =>{
        throw error;
      },async() =>{
        await uploadTask.snapshot.ref.getDownloadURL().then(async (url) =>{
          var jsonData = {};
          var temp= name+"URL"
          jsonData[temp] = url;
          
            
            const userRef = firestore.doc(`users/${user.uid}`);
            const snapshot = await userRef.get();
            if (snapshot.exists) {
            await userRef.update(jsonData);
          }
           img= jsonData;
        });
  
     }
   ) 

   return await uploadTask.snapshot.ref.getDownloadURL();
  }