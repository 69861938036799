

import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import React, { useContext } from 'react';
import { AuthContext } from '../userContext/userContext';
import {app} from '../firebase'
import './css/NavBar.css';
import login from '../Images/login.svg'

const NavBar3 = () =>{

    const {currentUser} = useContext(AuthContext);
    
    const logOut=async()=>{
        await app.auth().signOut();
        window.location.href="/login";
    }

    const Conditional= ()=>{
        if (currentUser) {
            if (currentUser.approved){
                return (
                    <div className="navbar-button-container">
                        <img src={login} className="navbar-button" />
                        <div className="navbar-text">
                            <a style={{color: '#30271e', fontSize: '28px'}} href="/account">ACCOUNT</a> 
                        </div>              
                    </div>                    
                    );
            }
            else {
                return (
                    <div className="navbar-button-container">
                        <img src={login} className="navbar-button" />
                        <div className="navbar-text">
                            <a style={{color: '#30271e', fontSize: '28px'}} href="login">ACCOUNT</a> 
                        </div>              
                    </div>  
                )          
            }
        }
        else{
            return "";
        }
    }

    return (
        <>
            <div className="navbar-container">
                {currentUser ? 
                    <div className="navbar-button-container">
                        <img src={login} className="navbar-button" />
                        <div className="navbar-text">
                            <a style={{color: '#30271e', fontSize: '28px'}} href="mission">MISSION</a> 
                        </div>              
                    </div>
                    :
                    <div className="navbar-button-container">
                        <img src={login} className="navbar-button" />
                        <div className="navbar-text">
                            <a style={{color: '#30271e', fontSize: '28px'}} href="login">LOGIN</a> 
                        </div>              
                    </div>
                }
                <Conditional/>
                <div className="navbar-button-container">
                    <img src={login} className="navbar-button" />
                    <div className="navbar-text">
                        <a style={{color: '#30271e', fontSize: '28px'}} href="rules">RULES</a> 
                    </div>              
                </div>
            </div>
        </>
    );
}


export default NavBar3;