import React, { useContext } from "react";
import { AuthContext } from "./userContext";
import { Navigate } from "react-router-dom";




const PrivateRoute = ({ children }) => {
  const {currentUser} = useContext(AuthContext);

  if (!currentUser){
    return(<Navigate to="/login" />);
  }
  else if (!currentUser.approved){
    return(<Navigate to="/account" />);
  }
  else{
    return children;
  }
};


export default PrivateRoute; 
