import React, { useState, useCallback, useContext } from 'react';
import { signInWithFacebook, signInWithGoogle } from '../firebase';
import facebook from '../Images/facebook.png';
import confidential from '../Images/confidential.jpg'
import authorized from '../Images/authorized.jpg'
import GoogleButton from 'react-google-button';
import { AuthContext } from '../userContext/userContext';
import { Navigate } from "react-router-dom";
import folder from '../Images/folder.svg'
import './css/LoginPage2.css'
import NavBar3 from '../Components/NavBar3';



const LoginPage =() =>{
    const {currentUser} = useContext(AuthContext);
    //console.log(currentUser);
    //console.log(Math.floor(Math.random()*90000) + 10000);

    if (currentUser){
        return(<Navigate to="/account" />);
    }
    else{

        return (
            <div className="login-page-container font-face-gm">
                <NavBar3 />
                {currentUser ? 
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <div className="login-info">
                            <h1>You are in the mainframe.</h1>
                            <br/>
                            <div></div>
                            <p style={{fontSize: '2.25ch'}}>View your target on the mission page. </p>
                            <p>The fate of DM depends on you.</p>
                            <br/>
                        </div>
                    </div>            
                :
                <div className="rules-container">
                    <img src={folder} className="folder"/>
                    <div className="rules-text">
                        <div className="rules-header font-face-gm">
                            <h1 style={{fontSize: "40px", marginTop: '10%'}}>Log into the mainframe</h1>
                        </div>
                        <div style={{fontSize: '15px', fontFamily: 'monospace', marginTop: '10%'}}>
                            <h1></h1>
                            <br/>
                            <p style={{marginBottom: '2%'}}>You will see your target once approved.</p>
                            <p style={{marginBottom: '2%'}}>After hours of work, we found a way to hack into the system to display
                                persons threatening the livelihood of DM.</p>
                            <p style={{marginBottom: '2%'}}>After paying you will get a secret phrase to approve your account </p>
                            <p style={{marginBottom: '2%'}}>Silly string at will.</p>
                            <br/>
                        </div>
                    </div>
                        <GoogleButton 
                            style={{borderRadius: '2px', bottom: '0', position: 'absolute', marginBottom: '20%'}} 
                            onClick={signInWithGoogle}>
                        </GoogleButton>
                    <br/><br></br>
                </div>
                }
            </div>
        );
    
    }
    

    


};

export default LoginPage;