
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import React, { useContext } from 'react';
import { AuthContext } from '../userContext/userContext';
import './css/LandingPage2.css'
import homeBackgroundImage from '../Images/homeBackgroundImage.png'
import {app} from '../firebase'
import camera from '../Images/camera.png'
import folder from '../Images/folder.svg'
import login from '../Images/login.svg'
import './css/RulesPage.css'
import NavBar3 from '../Components/NavBar3'

const RulesPage =() =>{
    const {currentUser} = useContext(AuthContext);

    const logOut=()=>{
        app.auth().signOut();
    }

    return (
        <div className="rules-page-container font-face-gm">
            <div></div>
            <NavBar3/>
            <div className="rules-container">
                <img src={folder} className="folder"/>
                <div className="rules-text">
                    <div className="rules-header font-face-gm">
                        <h1 style={{fontSize: "40px"}}>RULES</h1>
                    </div>
                    <div style={{fontSize: '10.4px', fontFamily: 'monospace'}}>
                        <p>1. All targets must be compromised using silly-string.</p>
                        <p>2. All UF classrooms and/or campus buildings are considered out of bounds. If you complete a mission inside a UF building, it will be discounted. This is a safe zone.</p>
                        <p>3. UF Health Shands and all hospital buildings are safe zones. No mission can be completed here.</p>
                        <p>4. Respect people’s privacy, respect those around you, and treat others as you would like to be treated.</p>
                        <p>5. Any participant who acts unlawfully or disrespectfully will be subject to elimination from Mission DM.</p>
                        <p>6. If you do not eliminate your target by the end of the round, you are automatically eliminated from Mission DM.</p>
                        <p>7. If you change your name on Google, you will also be subject to elimination from Mission DM.</p>
                        <p>8. Any violation of the rules above could be cause for further consequences such as removal from participating in future Dance Marathon events or removal from the organization entirely.</p>
                        <p>9. If you spray someone before they spray you, you have 30 minutes of immunity.</p>
                        <p>10. SAFE ZONES</p>
                            <p style={{marginLeft: '5%'}}>UF Health Shands Children’s Hospital and all associated buildings, 
                            Inside UF buildings, Mini Marathons (During and Inside the event), Miracles in Color 5K, 
                            Captain team meetings (During and Inside the meeting), Check-Ins</p>
                    </div>
                </div>
            </div>
            {!currentUser &&
            <div className="login-container" style={{marginTop: '6%', marginLeft: '0%', position: 'relative'}}>
                    <img src={login} className="login" />
                    <div className="login-text  font-face-gm">
                        <a href="login" style={{color: 'black'}}>
                            <h1 style={{fontSize: "96px", marginBottom: '0', alignSelf:" center"}}>LOGIN</h1></a>
                    </div>              
            </div>
            }
        </div>
    );
};

export default RulesPage;