import React, { useState, useCallback, useContext, useReducer } from 'react';
import { updateFirebaseObject, handleUserUpload, getUserDocument } from '../firebase';
import { AuthContext } from '../userContext/userContext';
import {Navigate} from 'react-router-dom';
import {Form, Button} from 'react-bootstrap';
import authorized from '../Images/authorized.jpg'
import './css/AccountPage.css'
import NavBar3 from '../Components/NavBar3';
import accountFolder from '../Images/accountFolder.svg';
import login from '../Images/login.svg'
import secretCode from '../Images/secretCode.svg'


const AccountPage = () =>{
    const [code,setCode]=useState("");
    const [loading,setLoading]=useState(false);
    const [repull, setRepull] = useState(false);

    
    const  handleFile= async(event)=>{
        
        event.preventDefault();
        
        if (event.target.profilePic.files[0]){
            setLoading(true);
            var pic = await handleUserUpload(currentUser,"userFiles",event.target.profilePic.files[0], "photo");
            setImg(pic);
            setLoading(false);
        }
    }

    function UploadButton() {
        return (
            <form onSubmit={handleFile}>
                    <div className="eliminate-container" style={{marginTop: '4%'}}>
                        <img src={login} className="eliminate" />
                        <div className="eliminate-text" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <input 
                                style={{backgroundColor: 'transparent', border: 'none', width: '60%',
                                        fontSize: '20px', color: '#DDD6B3', height: '5vh', marginBottom: '0'
                                    }} 
                                type="file" 
                                accept=".jpg, .png"
                                name ="profilePic" />
                            <button className="upload-button" type="submit">
                                Upload
                            </button>  
                        </div>        
                    </div>
          </form>
        );
      }


      const handleSubmit= async (event)=>{
        event.preventDefault();
        setLoading(true);
        let check=code.toLowerCase();
        if (check==="choosechange2022"){
            await updateFirebaseObject("users", currentUser.uid, {approved: true, alive:true});
            setCode("");
            setLoading(false);
            window.location.href= "/mission";
            
        }
        else{
            alert("Code Incorrect");
        }
    
        setLoading(false);
    }

    const {currentUser} = useContext(AuthContext);
    const [image,setImg]=useState(currentUser.photoURL);
    console.log(currentUser);


    if (!currentUser){
        window.location.href="/login";
        return(<p>Please log in</p>)
    }
    else if (loading){
        return(<h2>Loading ....</h2>);
    }
    else{
        return (
            <div className="account-page-container font-face-gm">
                <NavBar3/>
                <div className="logged-into-container ">
                    <div style={{width: '82%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <h1 style={{fontSize: '40px', marginTop: '3.5%'}}>Logged into mainframe</h1>
                    </div>
                </div>
                <div className="account-folder-container"> 
                    <img src={accountFolder} className="account-folder" />
                    <div className="account-image">
                        {currentUser ? 
                            <img style={{width: '100%'}} src={image}/>
                            :
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <h1 style={{fontSize: '100px', fontWeight: '800', alignSelf: 'center'}}>+</h1>
                            </div>
                        }  
                    </div>
                    <div className="upload-photo">
                        <h1 style={{marginBottom: '0'}}>Update your picture</h1>
                        <p style={{fontSize: '13px', color: '#30271e', marginBottom: '0', fontFamily: 'monospace', alignSelf: 'flex-start'}}>
                            You are a target too!
                        </p>
                        <p style={{fontSize: '13px', color: '#30271e', marginBottom: '0', fontFamily: 'monospace', alignSelf: 'flex-start'}}>
                            Please upload a profile picture so headquarters can confirm your identity.                        
                        </p>
                    </div>
                </div>


                {!loading? <UploadButton></UploadButton>:""}
                
                <div className="personal-code-container" style={{marginTop: '4%'}}>
                    <div className="personal-code-left">
                        <p style={{fontSize: '28px', color: '#30271e', marginBottom: '0'}}>personal code</p>
                    </div>
                    <div className="personal-code-middle" />
                    <div className="personal-code-left">
                        {currentUser.randomNum ? 
                            <p style={{fontSize: '28px', color: '#30271e', marginBottom: '0'}}>
                                {currentUser.randomNum}
                            </p>
                            : "12345"
                        }
                    </div>
                </div>
                
                <div className="secret-code-container">
                    <img src={secretCode} className="secret-code" />
                    <div className="secret-code-text">
                        <h1 style={{fontSize: "28px", marginBottom: '0', alignSelf:" center", marginTop: '3%'}}>
                            Secret Phrase
                        </h1>
                        {currentUser.approved && currentUser.alive ? 
                            <h1 style={{position: 'absolute', top: '0', marginTop: '20%', display: 'flex', justifyContent: 'space-evenly',
                                width: '100%'
                            }}>
                                APPROVED
                            </h1> 
                        :
                        <form 
                            style={{position: 'absolute', top: '0', marginTop: '20%', display: 'flex', justifyContent: 'space-evenly',
                                    width: '100%'
                                    }} 
                            onSubmit={handleSubmit}>
                            <input 
                                type="text" 
                                placeholder="Enter Phrase" 
                                name="code" 
                                required 
                                className="secret-phase-input" 
                                value={code} 
                                onChange={ (event) => { setCode(event.target.value) } } />
                            <button type="submit" className="secret-phase-button">
                                Submit
                            </button>
                        </form>
                        }
                       
                    </div>              
                </div>
            </div> 
        );}



};

export default AccountPage;