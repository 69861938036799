import React, { Component } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import LoginPage from './Pages/LoginPage';
import LandingPage from './Pages/LandingPage';
import LandingPage2 from './Pages/LandingPage2';
import HomePage from './Pages/HomePage';
import PasswordPage from './Pages/PasswordPage';
import { AuthProvider } from './userContext/userContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './Components/Navbar2';
import PrivateRoute from './userContext/privateRoute';
import AdminPage from './Pages/AdminPage';
import AccountPage2 from './Pages/AccountPage2';
import MissionPage from './Pages/MissionPage';
import LoginPage2 from './Pages/LoginPage2';
import AccountPage from './Pages/AccountPage';
import RulesPage from './Pages/RulesPage';

export const url= "https://missiondmbackend.uk.r.appspot.com";

function App() {
  return (
    <Router>
      <AuthProvider>
        {/* <NavBar></NavBar> */}
        <Routes>
          <Route path="/" element={<LandingPage2/> }/>
          <Route path="/mission" element={
            <PrivateRoute>
            <MissionPage></MissionPage>
            </PrivateRoute>
          }/>
          <Route path="/admin" element={
            <AdminPage></AdminPage>
          }/>
          
          <Route path="/rules" element={<RulesPage/>} />
          <Route path="/login" element={<LoginPage2/>} />
          <Route path="/account" element={<AccountPage/>} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
